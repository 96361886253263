import { useState } from "react";
import { GlobalApi } from "../globa";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";

function Form() {
  // handle form submission
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();

    const form = document.getElementById("login-form");
    try {
      setLoading(true);
      const response = await fetch(`${GlobalApi()}/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ username, password }),
      });

      if (response.ok) {
        const token = response.headers.get("Authorization");
        if (!token) {
          throw new Error("Invalid username or password");
        }
        const t = token.replace("Bearer ", "");
        Cookies.set("token", t, { expires: 7 });
        setLoading(false);
        navigate("/");
      } else {
        setError("Invalid username or password");
        setUsername("");
        setPassword("");
        form.username.focus();
        setLoading(false);
      }
    } catch (error) {
      console.error("Error:", error);
      setUsername("");
      setPassword("");
      setError("Invalid username or password");
      setLoading(false);
    }
  };
  return (
    <form id="login-form" onSubmit={handleSubmit}>
      <div id="error" className="text-red-500 text-sm my-3">
        <p>{error}</p>
      </div>
      <div className="flex flex-col space-y-4 gap-4">
        <div className="w-full mb-4">
          <input
            autoCapitalize="none"
            autoCorrect="off"
            type="text"
            id="username"
            name="username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
            placeholder="Username"
            autoComplete="username"
            autoFocus
            className="w-full bg-gray-[#121C22] text-gray-400 p-1 border rounded-md border-primary bg-chat focus:ring focus:outline-none"
          />
        </div>
      </div>
      <div className="mb-4">
        <input
          type="password"
          id="password"
          name="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
          autoComplete="current-password"
          placeholder="Password"
          className="w-full bg-gray-[#121C22] text-gray-400 p-1 border rounded-md border-primary bg-chat focus:ring focus:outline-none"
        />
      </div>
      <div className="mb-4">
        <button
          type="submit"
          className="flex items-center gap-2 justify-center w-full bg-primary text-gray-700 font-bold text-md py-2 px-4 rounded-md hover:bg-primary/75 focus:outline-none focus:bg-gray-400 ease-out duration-75"
        >
          login
          {loading && (
            <span className="spinner" id="spinner">
              <svg
                className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                ></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
            </span>
          )}
        </button>
      </div>
    </form>
  );
}

function Login() {
  return (
    <div className="flex items-center justify-center min-h-screen bg-base">
      <div className="bg-chat  shadow-sm shadow-gray-700 rounded-md p-8 w-80 h-full">
        <div className="p-8 font-brand text-center text-3xl bg-gradient-to-r from-pink-400 via-red-400 to-orange-400 text-transparent bg-clip-text">
          Login
        </div>
        <Form />
      </div>
    </div>
  );
}

export default Login;
