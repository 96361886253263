function GlobalApi() {
  //return "http://localhost:3004";
  return "https://llm-api.waxmaker.app";
}

function GlobalSocket() {
  //return "ws://localhost:3004/ws";
  return "wss://llm-api.waxmaker.app/ws";
}

export { GlobalApi, GlobalSocket };
