import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";

History.propTypes = {
  ws: PropTypes.oneOfType([PropTypes.object, PropTypes.oneOf([null])]),
  setHistory: PropTypes.func.isRequired,
  history: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
      created: PropTypes.string,
    }),
  ).isRequired,
};

function History({ history, ws, setHistory }) {
  const navigate = useNavigate();
  const query = new URLSearchParams(window.location.search);
  const selectedId = query.get("id");

  const handleDeleteHistory = (id) => {
    const message = {
      key: "history",
      arg: "delete",
      id: id,
    };
    if (ws !== null) {
      ws.send(JSON.stringify(message));
      setHistory((prevHistory) => prevHistory.filter((item) => item.id !== id));
      console.log(selectedId, id);
      if (id === selectedId) {
        navigate("/");
      }
    }
  };

  return (
    <div className="flex flex-col gap-4">
      <div className="flex gap-2 bg-primary p-2 rounded-md justify-between text-primary_token text-lg font-semibold hover:opacity-65 ease-in-out duration-200 cursor-pointer my-4">
        <button
          onClick={() => navigate("/")}
          className="flex items-center justify-between w-full text-token-text-primary rounded-md"
        >
          <p>New chat</p>
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill="currentColor"
              d="M5 21q-.825 0-1.412-.587T3 19V5q0-.825.588-1.412T5 3h5q.425 0 .713.288T11 4t-.288.713T10 5H5v14h14v-5q0-.425.288-.712T20 13t.713.288T21 14v5q0 .825-.587 1.413T19 21zM16 8h-2q-.425 0-.712-.288T13 7t.288-.712T14 6h2V4q0-.425.288-.712T17 3t.713.288T18 4v2h2q.425 0 .713.288T21 7t-.288.713T20 8h-2v2q0 .425-.288.713T17 11t-.712-.288T16 10z"
            ></path>
          </svg>
        </button>
      </div>
      <div className="scrollbar flex relative flex-col gap-3 overflow-y-auto text-primary_token font-semibold">
        <ol className="flex flex-col gap-2">
          {history.map((item) => (
            <li
              className="relative cursor-pointer"
              id="li"
              key={item.id}
              onClick={() => navigate(`/?model=${item.modelid}&id=${item.id}`)}
            >
              <div
                className={
                  item.id === selectedId
                    ? "relative rounded-lg active:opacity-90 bg-gray-600 hover:bg-gray-700 group"
                    : "relative rounded-lg active:opacity-90 hover:bg-gray-700 group"
                }
              >
                <div
                  className="w-full flex gap-2 p-2 rounded-md ease-in-out duration-200"
                  title={item.title}
                >
                  <p className="w-full overflow-hidden whitespace-nowrap text-overflow-ellipsis">
                    {item.title}
                  </p>
                </div>
                <div className="absolute bottom-0 right-0 top-0 items-center gap-1.5 pr-2 hidden group-hover:flex pl-1 z-50">
                  <button
                    id={item.id}
                    className="flex z-50 items-center justify-center bg-gray-700 pl-2 rounded-lg text-token-text-primary transition group-hover:text-token-text-secondary radix-state-open:text-token-text-secondary"
                    type="button"
                    onClick={() => {
                      handleDeleteHistory(item.id);
                    }}
                  >
                    <span className="">
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill="currentColor"
                          d="M7 21q-.825 0-1.412-.587T5 19V6H4V4h5V3h6v1h5v2h-1v13q0 .825-.587 1.413T17 21zm2-4h2V8H9zm4 0h2V8h-2z"
                        ></path>
                      </svg>
                    </span>
                  </button>
                </div>
              </div>
            </li>
          ))}
        </ol>
      </div>
    </div>
  );
}

SideBar.propTypes = {
  ws: PropTypes.oneOfType([PropTypes.object, PropTypes.oneOf([null])]),
  setHistory: PropTypes.func.isRequired,
  history: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
      created: PropTypes.string,
    }),
  ).isRequired,
};

function SideBar({ history, ws, setHistory }) {
  return (
    <div className="text-white flex-shrink-0 overflow-x-hidden bg-base w-64 h-full min-h-[calc(100vh-64px)]">
      <div className="h-full w-64 p-2">
        <div className="flex h-full min-h-0 flex-col">
          <div className="w-full w-full flex-1 items-center border-white">
            <nav className="w-full p-2 flex flex-col gap-2">
              <div id="history" className="flex flex-col">
                <History history={history} ws={ws} setHistory={setHistory} />
              </div>
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SideBar;
