import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";

SidebarProfile.propTypes = {
  page: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]),
};

const className =
  "flex items-center justify-start gap-2 p-2 rounded-md ease-in-out duration-500 border-white/25 ";
const classNameSelect = "bg-primary hover:bg-chat border-[0.5px]";
const classNameNoSelect = "hover:bg-primary bg-chat pl-2";

const Menu = [
  {
    id: "Profile",
    menu: "Profile",
    link: "/profile",
    svg: (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill="currentColor"
          d="M6 13h9v-2H6zm0-3h9V8H6zM4 20q-.825 0-1.412-.587T2 18V6q0-.825.588-1.412T4 4h16q.825 0 1.413.588T22 6v12q0 .825-.587 1.413T20 20zm0-2h16V6H4zm0 0V6z"
        />
      </svg>
    ),
  },
  {
    id: "Prompt",
    menu: "Prompt",
    link: "/profile?settings=Prompt",
    svg: (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill="currentColor"
          d="M17.5 18.5v2q0 .2.15.35T18 21t.35-.15t.15-.35v-2h2q.2 0 .35-.15T21 18t-.15-.35t-.35-.15h-2v-2q0-.2-.15-.35T18 15t-.35.15t-.15.35v2h-2q-.2 0-.35.15T15 18t.15.35t.35.15zM18 23q-2.075 0-3.537-1.463T13 18t1.463-3.537T18 13t3.538 1.463T23 18t-1.463 3.538T18 23M8 9h8q.425 0 .713-.288T17 8t-.288-.712T16 7H8q-.425 0-.712.288T7 8t.288.713T8 9M5 21q-.825 0-1.412-.587T3 19V5q0-.825.588-1.412T5 3h14q.825 0 1.413.588T21 5v5.45q0 .45-.375.7t-.8.1q-.425-.125-.888-.188T18 11q-.275 0-.513.013t-.487.062q-.225-.05-.5-.062T16 11H8q-.425 0-.712.288T7 12t.288.713T8 13h5.125q-.45.425-.812.925T11.675 15H8q-.425 0-.712.288T7 16t.288.713T8 17h3.075q-.05.25-.062.488T11 18q0 .5.05.95t.175.875t-.125.8t-.675.375z"
        />
      </svg>
    ),
  },
  {
    id: "Documentation",
    menu: "Documentation",
    link: "/profile?settings=Documentation",
    svg: (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill="currentColor"
          d="M5 4v16zv5zm2 10h3.675q.275-.575.638-1.075t.812-.925H7zm0 4h3.075Q10 17.5 10 17t.075-1H7zm-2 4q-.825 0-1.412-.587T3 20V4q0-.825.588-1.412T5 2h8l6 6v2.3q-.475-.15-.975-.225T17 10V9h-5V4H5v16h5.675q.275.575.638 1.075t.812.925zm12-10q2.075 0 3.538 1.463T22 17t-1.463 3.538T17 22t-3.537-1.463T12 17t1.463-3.537T17 12m0 8q.275 0 .463-.187t.187-.463t-.187-.462T17 18.7t-.462.188t-.188.462t.188.463T17 20m-.45-1.9h.9v-.25q0-.275.15-.488t.35-.412q.35-.3.55-.575t.2-.775q0-.725-.475-1.162T17 14q-.575 0-1.037.338t-.663.912l.8.35q.075-.3.313-.525T17 14.85q.375 0 .588.188t.212.562q0 .275-.15.463t-.35.387q-.15.15-.312.3t-.288.35q-.075.15-.112.3t-.038.35z"
        />
      </svg>
    ),
  },
  {
    id: "Api-keys",
    menu: "Api keys",
    link: "/profile?settings=Api-keys",
    svg: (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill="currentColor"
          d="M6 22q-.825 0-1.412-.587T4 20V10q0-.825.588-1.412T6 8h1V6q0-2.075 1.463-3.537T12 1t3.538 1.463T17 6v2h1q.825 0 1.413.588T20 10v10q0 .825-.587 1.413T18 22zm6-5q.825 0 1.413-.587T14 15t-.587-1.412T12 13t-1.412.588T10 15t.588 1.413T12 17M9 8h6V6q0-1.25-.875-2.125T12 3t-2.125.875T9 6z"
        />
      </svg>
    ),
  },
  {
    id: "Settings",
    menu: "Settings",
    link: "/profile?settings=Settings",
    svg: (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill="currentColor"
          d="m9.25 22l-.4-3.2q-.325-.125-.612-.3t-.563-.375L4.7 19.375l-2.75-4.75l2.575-1.95Q4.5 12.5 4.5 12.338v-.675q0-.163.025-.338L1.95 9.375l2.75-4.75l2.975 1.25q.275-.2.575-.375t.6-.3l.4-3.2h5.5l.4 3.2q.325.125.613.3t.562.375l2.975-1.25l2.75 4.75l-2.575 1.95q.025.175.025.338v.674q0 .163-.05.338l2.575 1.95l-2.75 4.75l-2.95-1.25q-.275.2-.575.375t-.6.3l-.4 3.2zM11 20h1.975l.35-2.65q.775-.2 1.438-.587t1.212-.938l2.475 1.025l.975-1.7l-2.15-1.625q.125-.35.175-.737T17.5 12t-.05-.787t-.175-.738l2.15-1.625l-.975-1.7l-2.475 1.05q-.55-.575-1.212-.962t-1.438-.588L13 4h-1.975l-.35 2.65q-.775.2-1.437.588t-1.213.937L5.55 7.15l-.975 1.7l2.15 1.6q-.125.375-.175.75t-.05.8q0 .4.05.775t.175.75l-2.15 1.625l.975 1.7l2.475-1.05q.55.575 1.213.963t1.437.587zm1.05-4.5q1.45 0 2.475-1.025T15.55 12t-1.025-2.475T12.05 8.5q-1.475 0-2.488 1.025T8.55 12t1.013 2.475T12.05 15.5M12 12"
        />
      </svg>
    ),
  },
];

function SidebarProfile({ page }) {
  const navigate = useNavigate();
  return (
    <div className="text-white flex-shrink-0 overflow-x-hidden bg-base w-64 min-h-[calc(100vh-64px)]">
      <div className="h-full w-64 p-2">
        <div className="flex h-full min-h-0 flex-col">
          <div className="scrollbar-trigger h-full w-full flex-1 items-center border-white">
            <nav className="w-full p-2">
              <ul className="flex flex-col gap-4">
                {Menu.map((item) => (
                  <li key={item.id}>
                    {page === item.id ? (
                      <div className={className + classNameSelect}>
                        {item.svg}
                        <button
                          className="w-full h-full flex justify-start"
                          onClick={() => navigate(item.link)}
                        >
                          {item.menu}
                        </button>
                      </div>
                    ) : (
                      <div className={className + classNameNoSelect}>
                        {item.svg}
                        <button
                          className="w-full h-full flex justify-start"
                          onClick={() => navigate(item.link)}
                        >
                          {item.menu}
                        </button>
                      </div>
                    )}
                  </li>
                ))}
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SidebarProfile;
