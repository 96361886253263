import Cookies from "js-cookie";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

function Header() {
  const navigate = useNavigate();
  const [dropdown, setDropdown] = useState(false);

  function toggleDropdown() {
    setDropdown(!dropdown);
  }

  function logout() {
    setDropdown(false);
    Cookies.remove("token");
    navigate("/login");
  }

  return (
    <div className="relative navbar bg-base border-b border-baseLight top-0 px-4 z-10 flex items-center w-screen min-h-16 justify-between">
      <div className="navbar-start flex items-center justify-between w-full">
        <a href="/">
          <div className="flex items-center gap-2">
            <img src="/Logo.png" alt="logo" className="w-9 h-9" />
            <div className="font-brand text-3xl bg-gradient-to-r from-pink-400 via-red-400 to-orange-400 inline-block text-transparent bg-clip-text">
              llmchat.ia
            </div>
          </div>
        </a>
        <button
          id="dropdownDefaultButton"
          onClick={toggleDropdown}
          className="text-primary_token hover:bg-chat font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center"
          type="button"
        >
          My account
          <svg
            className="w-2.5 h-2.5 ms-3"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 10 6"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="m1 1 4 4 4-4"
            ></path>
          </svg>
        </button>
        {dropdown && (
          <div
            id="dropdown"
            className="absolute top-full right-4 z-10 bg-base divide-y rounded-lg shadow w-36 shadow-white/45"
          >
            <ul
              className="flex flex-col items-start w-full py-2 text-sm text-gray-700 dark:text-gray-200"
              aria-labelledby="dropdownDefaultButton"
            >
              <li className="w-full">
                <a
                  href="/profile"
                  className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                >
                  My profile
                </a>
              </li>
              <li className="w-full">
                <button
                  id="logout"
                  className="w-full text-left block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                  onClick={logout}
                >
                  Log out
                </button>
              </li>
            </ul>
          </div>
        )}
      </div>
    </div>
  );
}

export default Header;
