import Cookies from "js-cookie";
import { GlobalApi } from "./globa";

export const checkAuth = async () => {
  try {
    const token = Cookies.get("token");
    const response = await fetch(`${GlobalApi()}/api/auth`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      return false;
    }
    return true;
  } catch (error) {
    return false;
  }
};
