import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./pages/home";
import Login from "./pages/login";
import Profile from "./pages/profile";

function App() {
  return (
    <BrowserRouter>
      <div id="llm-app" className="bg-base min-h-screen min-w-screen">
        <div className="absolute z-20 top-0 inset-x-0 flex justify-center overflow-hidden pointer-events-none">
          <div className="w-[108rem] flex-none flex justify-end">
            <picture>
              <source srcSet="/effect.avif" type="image/avif" />
              <img
                src="/effect.avif"
                alt=""
                className="w-[71.75rem] flex-none max-w-none opacity-40"
                decoding="async"
              />
            </picture>
          </div>
        </div>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/login" element={<Login />} />
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
