import Header from "../conponents/header";
import SidebarProfile from "../conponents/sidebar_profile";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import Apikey from "../conponents/Apikey";

Header_Profile.propTypes = {
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]),
};

function Header_Profile({ name }) {
  return (
    <div className="p-4 h-[64px] w-full border-b-[1px] border-white/10">
      <p className="text-white/50 font-semibold text-lg">{name}</p>
    </div>
  );
}

function Profile() {
  const [profile, setProfile] = useState(null);
  const location = useLocation();

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    const profile = query.get("settings");
    if (profile === null) {
      setProfile("profile");
    } else {
      setProfile(profile);
    }
  }, [profile, location]);
  return (
    <>
      <Header />
      <div className="relative z-0 flex min-h-[calc(100vh-64px)] w-full overflow-hidden bg-chat">
        <SidebarProfile page={profile} />
        <div className="profil w-full flex flex-col">
          <Header_Profile name={profile} />
          {profile === "Api-keys" && <Apikey />}
        </div>
      </div>
    </>
  );
}

export default Profile;
