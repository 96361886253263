import { useState } from "react";
import { GlobalApi } from "../globa";
import Cookies from "js-cookie";
import PropTypes from "prop-types";

New_api.propTypes = {
  setNewApi: PropTypes.func.isRequired,
};

function New_api({ setNewApi }) {
  const [name, setName] = useState("");
  const [api, setApi] = useState("");
  const [hidden, setHidden] = useState(true);
  const [error, setError] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const token = Cookies.get("token");
      const response = await fetch(`${GlobalApi()}/settings/new_api`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ name }),
      });
      if (response.ok) {
        setName("");
        const data = await response.json();
        console.log(data);
        setApi(data.api);
      } else {
        const data = await response.json();
        setError(data.message);
      }
    } catch (error) {
      console.log(error);
      setName("");
    }
  };

  const handleClose = () => {
    setNewApi(false);
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(api);
    // change text
    const copy = document.getElementById("copy_api");
    copy.textContent = "Copied";
    setHidden(true);
    setTimeout(() => {
      copy.textContent = "Copy";
      setHidden(false);
    }, 2000);
  };

  return (
    <div className="absolute h-screen w-screen bg-black/50 top-0 left-0 flex items-center justify-center">
      {api !== "" ? (
        <div className="fixed text-white left-[50%] top-[50%] z-50 grid w-full translate-x-[-50%] translate-y-[-50%] gap-4 border-primary bg-chat p-6 shadow-lg duration-200 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[state=closed]:slide-out-to-left-1/2 data-[state=closed]:slide-out-to-top-[48%] data-[state=open]:slide-in-from-left-1/2 data-[state=open]:slide-in-from-top-[48%] sm:rounded-lg max-w-[400px] md:max-w-2xl">
          <div className="flex flex-col space-y-1.5 text-center sm:text-left">
            <h1 className="text-xl font-semibold text-white">Create API Key</h1>
            <button
              onClick={handleClose}
              className="absolute top-0 p-2 right-2"
            >
              x
            </button>
          </div>
          <form className="fles flex-col space-y-4" onSubmit={handleSubmit}>
            <div className="space-y-2">
              <p className="text-white/50">
                Your new API key has been created, Copy it now, as we will not
                display it again
              </p>
              <div className="flex gap-2 items-center">
                <input
                  className="flex text-white h-10 w-full rounded-md border border-primary bg-chat px-3 py-2 text-sm file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus:border-ring focus:outline-none disabled:cursor-not-allowed disabled:opacity-50 focus-visible:ring-0 focus-visible:border-ring max-w-xs md:max-w-none"
                  name="api"
                  value={api}
                />
                <button
                  className="flex items-center text-white items-center p-2 bg-primary rounded-md space-x-2"
                  onClick={handleCopy}
                >
                  <p id="copy_api">Copy</p>
                  {hidden === false ? (
                    <svg
                      id="copy_svg"
                      className=""
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill="currentColor"
                        d="M9 18q-.825 0-1.412-.587T7 16V4q0-.825.588-1.412T9 2h9q.825 0 1.413.588T20 4v12q0 .825-.587 1.413T18 18zm0-2h9V4H9zm-4 6q-.825 0-1.412-.587T3 20V7q0-.425.288-.712T4 6t.713.288T5 7v13h10q.425 0 .713.288T16 21t-.288.713T15 22zm4-6V4z"
                      />
                    </svg>
                  ) : (
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill="currentColor"
                        d="m9.55 18l-5.7-5.7l1.425-1.425L9.55 15.15l9.175-9.175L20.15 7.4z"
                      />
                    </svg>
                  )}
                </button>
              </div>
            </div>
            <div className="flex justify-end items-center">
              <button
                className="btn p-2 bg-primary hover:bg-primary/60 rounded-md"
                onClick={handleClose}
              >
                Done
              </button>
            </div>
          </form>
        </div>
      ) : (
        <div className="fixed left-[50%] top-[50%] z-50 grid w-full text-white translate-x-[-50%] translate-y-[-50%] gap-4 border-primary bg-chat p-6 shadow-lg duration-200 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[state=closed]:slide-out-to-left-1/2 data-[state=closed]:slide-out-to-top-[48%] data-[state=open]:slide-in-from-left-1/2 data-[state=open]:slide-in-from-top-[48%] sm:rounded-lg max-w-[400px] md:max-w-2xl">
          <button onClick={handleClose} className="absolute top-0 p-2 right-2">
            x
          </button>
          <div className="flex flex-col space-y-1.5 text-center sm:text-left">
            <h1 className="text-xl font-semibold text-white">Create API Key</h1>
            {error && <p className="text-red-500 text-sm">*{error}</p>}
          </div>
          <form className="fles flex-col space-y-4" onSubmit={handleSubmit}>
            <div className="space-y-2">
              <p className="text-white/50">Enter a display name for the key</p>
              <input
                className="flex h-10 w-full text-white rounded-md border border-primary bg-chat px-3 py-2 text-sm file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus:border-ring focus:outline-none disabled:cursor-not-allowed disabled:opacity-50 focus-visible:ring-0 focus-visible:border-ring max-w-xs md:max-w-none"
                name="name"
                onChange={(e) => setName(e.target.value)}
                value={name}
                placeholder="Name"
              />
            </div>
            <div className="flex justify-end items-center">
              <button
                type="submit"
                className="btn p-2 bg-primary hover:bg-primary/60 rounded-md"
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      )}
    </div>
  );
}

export default New_api;
