import { useEffect, useState } from "react";
import { checkAuth } from "../auth";
import Header from "../conponents/header";
import SideBar from "../conponents/sidebar";
import { useNavigate } from "react-router-dom";
import { GlobalSocket } from "../globa";
import Cookies from "js-cookie";
import Model from "../conponents/model";
import Chat from "../conponents/chat";

function Home() {
  // init socket
  const [socket, setSocket] = useState(null);
  const [history, setHistory] = useState([]);
  const [model, setModel] = useState([]);
  const query = new URLSearchParams(window.location.search);
  const selectedId = query.get("id");

  const navigate = useNavigate();
  useEffect(() => {
    const Auth = async () => {
      const resp = await checkAuth();
      if (!resp) {
        navigate("/login");
      }
    };
    Auth();
    window.addEventListener("beforeunload", Auth);
    return () => {
      window.removeEventListener("beforeunload", Auth);
    };
  }, []);

  useEffect(() => {
    // Créer une nouvelle instance WebSocket
    const token = Cookies.get("token");
    const ws = new WebSocket(`${GlobalSocket()}?token=${token}`);

    ws.onopen = () => {
      // get history
      const history = {
        key: "history",
        arg: "list",
        data: {},
      };
      const model = {
        key: "model",
        arg: "list",
        data: {},
      };
      try {
        ws.send(JSON.stringify(history));
        ws.send(JSON.stringify(model));
      } catch (e) {
        console(e);
      }
    };

    ws.onmessage = (event) => {
      try {
        const message = JSON.parse(event.data);
        if (message.key === "history") {
          if (message.arg === "list") {
            setHistory(message.data);
          } else if (message.arg === "delete") {
            console.log("delete", message.data);
          }
        } else if (message.key === "model") {
          setModel(message.data);
        }
      } catch (e) {
        console.log(e);
      }
    };

    ws.onclose = () => {
      setSocket(null);
      setTimeout(() => {
        setSocket(new WebSocket(`${GlobalSocket()}?token=${token}`));
      }, 3000);
    };

    ws.onerror = (error) => {
      console.log("WebSocket error:", error);
    };

    setSocket(ws);

    // Cleanup on component unmount
  }, []);

  return (
    <>
      <Header />
      <div className="relative z-0 flex min-h-[calc(100vh-64px)] w-full overflow-hidden bg-chat">
        <SideBar history={history} ws={socket} setHistory={setHistory} />
        <div className="home_selection_models w-full flex items-center justify-center">
          {!selectedId && (
            <div className="flex flex-col items-center gap-2">
              <img src="/Logo.png" alt="logo" className="w-14 h-14" />
              <h1 className="text-3xl text-primary_token">
                Welcome Select your models for start
              </h1>
              <div id="models" className="flex flex-col">
                <Model model={model} />
              </div>
            </div>
          )}
          {selectedId && <Chat socket={socket} />}
        </div>
      </div>
    </>
  );
}

export default Home;
