import PropTypes from "prop-types";
import { v4 as uuidv4 } from "uuid";

import { useNavigate } from "react-router-dom";
Model.propTypes = {
  model: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      description: PropTypes.string,
      iconeurl: PropTypes.string,
    }),
  ).isRequired,
};

function Model({ model }) {
  const navigate = useNavigate();

  const handle = (item) => {
    //gen uuid
    const uuid = uuidv4();
    navigate(`/?model=${item.id}&id=${uuid})`);
  };
  return (
    <ul className="models flex gap-6 w-full my-6">
      {model.map((item) => (
        <li
          key={item.id}
          id={item.id}
          title={item.description}
          onClick={() => handle(item)}
          className="flex w-full text-2xl text-primary_token p-2 rounded-lg border border-gray-600 hover:bg-gray-500 cursor-pointer"
        >
          <div className="model flex items-center w-full ease-in-out duration-150 cursor-pointer max-h-28">
            <p className="text-2xl text-primary_token p-2">{item.name}</p>
          </div>
        </li>
      ))}
    </ul>
  );
}

export default Model;
