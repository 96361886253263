import PropTypes from "prop-types";
import Highlight from "react-highlight.js";
import React from "react";
import "highlight.js/styles/atom-one-dark.css";
import { Loading_chat } from "./loading";

Paragraph.propTypes = {
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]),
};

function Paragraph({ content }) {
  return (
    <div className="flex items-center">
      <p className="">{content}</p>
    </div>
  );
}
CodeBlock.propTypes = {
  code: PropTypes.string.isRequired,
  tag: PropTypes.string.isRequired,
  code_content: PropTypes.arrayOf(PropTypes.string).isRequired,
};

// Composant pour <pre>
function CodeBlock({ code, tag, code_content }) {
  const content_line = code_content.join("");

  const handleCopy = (code_content) => {
    const copy = document.getElementById(`content-${tag}`);
    if (copy !== null) {
      navigator.clipboard.writeText(code_content.join("\n"));
      copy.textContent = "Code copied!";
      setTimeout(() => {
        copy.textContent = "Copy code";
      }, 2000);
    }
  };
  return (
    <div className="scrollbar h-full overflow-x-auto my-3">
      <div className="dark bg-code rounded-md border-[0.2px] h-ful border-primary shadow drop-shadow-sm">
        <div className="flex items-center relative p-4 text-xs h-full font-sans justify-between rounded-t-md bg-gradient-to-b from-white/10 to-white/0">
          <span>{code}</span>
          <div className="flex items-center h-full">
            <span className="" data-state="closed">
              <button
                id={`copy-${tag}"`}
                onClick={() => handleCopy(code_content)}
                className="flex gap-1 items-center p-2 hover:bg-primary/65 rounded-md ease-in-out duration-300"
                type="button"
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="icon-sm"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12 3.5C10.8954 3.5 10 4.39543 10 5.5H14C14 4.39543 13.1046 3.5 12 3.5ZM8.53513 3.5C9.22675 2.3044 10.5194 1.5 12 1.5C13.4806 1.5 14.7733 2.3044 15.4649 3.5H17.25C18.9069 3.5 20.25 4.84315 20.25 6.5V18.5C20.25 20.1569 19.1569 21.5 17.25 21.5H6.75C5.09315 21.5 3.75 20.1569 3.75 18.5V6.5C3.75 4.84315 5.09315 3.5 6.75 3.5H8.53513ZM8 5.5H6.75C6.19772 5.5 5.75 5.94772 5.75 6.5V18.5C5.75 19.0523 6.19772 19.5 6.75 19.5H17.25C18.0523 19.5 18.25 19.0523 18.25 18.5V6.5C18.25 5.94772 17.8023 5.5 17.25 5.5H16C16 6.60457 15.1046 7.5 14 7.5H10C8.89543 7.5 8 6.60457 8 5.5Z"
                    fill="currentColor"
                  ></path>
                </svg>
                <p id={`content-${tag}`}>Copy code</p>
              </button>
            </span>
          </div>
        </div>
        <div className="rounded-md h-full my-6">
          <Highlight
            language={code}
            className="scrollbar overflow-x-auto rounded-md h-full"
          >
            {content_line}
          </Highlight>
        </div>
      </div>
    </div>
  );
}

UnorderedList.propTypes = {
  items: PropTypes.arrayOf(PropTypes.string).isRequired,
  typeList: PropTypes.oneOf(["DISC", "DECIMAL"]).isRequired,
};
// Composant pour <ul>
function UnorderedList({ items, typeList }) {
  return (
    <ul
      className={`text-wrap list-outside ${typeList === "DISC" ? "list-disc" : "list-decimal"}`}
    >
      {items.map((item, index) => (
        <li key={index} className="list py-1">
          {item}
        </li>
      ))}
    </ul>
  );
}

DisplayChat.propTypes = {
  socket: PropTypes.oneOfType([PropTypes.object, PropTypes.oneOf([null])]),
  loading: PropTypes.bool.isRequired,
  msg: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      role: PropTypes.string.isRequired,
      html: PropTypes.arrayOf(
        PropTypes.shape({
          tags: PropTypes.oneOf(["P", "PRE", "UL"]).isRequired,
          context: PropTypes.string,
          type_list: PropTypes.oneOf(["disc", "decimal", null]),
          list_value: PropTypes.number,
          list: PropTypes.arrayOf(PropTypes.string).isRequired,
          code_content: PropTypes.arrayOf(PropTypes.string).isRequired,
          code: PropTypes.string.isRequired,
          code_done: PropTypes.bool.isRequired,
          tag: PropTypes.string.isRequired,
        }),
      ).isRequired,
    }),
  ).isRequired, // Marquez le tableau de messages comme requis
};

Header.propTypes = {
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  socket: PropTypes.oneOfType([PropTypes.object, PropTypes.oneOf([null])]),
};

function Header({ name, id, socket }) {
  const handleDeleteHistory = (delete_id) => {
    if (socket !== null) {
      const message = {
        key: "chat",
        arg: "delete",
        data: { id: delete_id, model: "" },
      };
      try {
        socket.send(JSON.stringify(message));
      } catch (e) {
        console.log(e);
      }
    }
  };
  if (name === "user") {
    return (
      <div className="flex items-center justify-between gap-2">
        <div className="flex items-center justify-start gap-2">
          <div className="w-6 h-6 rounded-full bg-gradient-to-r from-pink-400 via-red-400 to-orange-400 "></div>
          <h1 className="text-xl">User:</h1>
        </div>

        <button
          id={`button-${id}`}
          className="flex z-50 items-center justify-center text-token-text-primary transition group-hover:text-token-text-secondary radix-state-open:text-token-text-secondary"
          type="button"
          onClick={() => {
            handleDeleteHistory(id);
          }}
        >
          <span className="">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill="currentColor"
                d="M7 21q-.825 0-1.412-.587T5 19V6H4V4h5V3h6v1h5v2h-1v13q0 .825-.587 1.413T17 21zm2-4h2V8H9zm4 0h2V8h-2z"
              ></path>
            </svg>
          </span>
        </button>
      </div>
    );
  } else {
    return (
      <div className="flex items-center justify-start gap-2">
        <img src="Logo.png" alt="logo" className="w-6 h-6" />
        <h1 className="text-xl">Assistant:</h1>
      </div>
    );
  }
}

function DisplayChat({ msg, socket, loading }) {
  if (msg === null) {
    return <p>Loading...</p>;
  }
  return (
    <React.Fragment>
      {msg.map((element, index) => (
        <div
          key={index}
          className="w-full h-full text-wrap text-primary_token gap-8 my-3 rounded-md bg-white/[.024] p-4 shadow-md"
        >
          <div className="flex flex-col h-full justify-start py-2">
            <Header name={element.role} id={element.id} socket={socket} />
            <div className="flex flex-col justify-start p-4 px-8">
              {element.html.map((html, index) => {
                switch (html.tags) {
                  case "P":
                    return <Paragraph key={index} content={html.context} />;
                  case "PRE":
                    return (
                      <CodeBlock
                        key={index}
                        code={html.code}
                        tag={html.tag}
                        code_content={html.code_content}
                      />
                    );
                  case "UL":
                    return (
                      <UnorderedList
                        key={index}
                        items={html.list}
                        typeList={html.type_list}
                      />
                    );
                }
              })}
            </div>
          </div>
        </div>
      ))}
      {loading && <Loading_chat />}
    </React.Fragment>
  );
}

export default DisplayChat;
