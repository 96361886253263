function Loading() {
  return (
    <div className="flex items-center justify-center h-screen">
      <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-primary"></div>
      <div className="flex items-center justify-center gap-2 animate-pulse">
        <p className="ml-4">Loading</p>
        <div className="rounded-full bg-primary/20 h-2 w-2"></div>
        <div className="rounded-full bg-primary/20 h-2 w-2"></div>
        <div className="rounded-full bg-primary/20 h-2 w-2"></div>
      </div>
    </div>
  );
}

function Loading_history() {
  return (
    <div className="flex items-center justify-center w-full h-12">
      <div className="animate-spin rounded-full h-6 w-6 border-t-2 border-b-2 border-primary"></div>
    </div>
  );
}

function Loading_chat() {
  return (
    <div className="animate-pulse w-full">
      <div className="flex flex-col gap-4 w-full">
        <div className="flex items-center justify-start gap-4">
          <div className="rounded-full bg-primary/20 h-12 w-12"></div>
          <div className="h-4 bg-primary/20 w-1/3 rounded-lg"></div>
        </div>
        <div className="ml-16 h-4 bg-primary/20 w-4/6 rounded-lg"></div>
        <div className="ml-16 h-4 bg-primary/20 w-4/6 rounded-lg"></div>
        <div className="ml-16 h-4 bg-primary/20 w-4/6 rounded-lg"></div>
      </div>
    </div>
  );
}

export { Loading, Loading_chat, Loading_history };
