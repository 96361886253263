import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import DisplayChat from "./display_chat";
import { Loading, Loading_history } from "./loading";

const HISTORY = 2;

FormPrompt.propTypes = {
  socket: PropTypes.oneOfType([PropTypes.object, PropTypes.oneOf([null])]),
  selectedId: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]),
  selectedModel: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.oneOf([null]),
  ]),
};

function FormPrompt({ socket, selectedId, selectedModel }) {
  const [prompt, setPrompt] = useState("");
  const [rows, setRows] = useState(2);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (socket !== null) {
      const message = {
        key: "chat",
        arg: "add",
        data: {
          id: selectedId,
          model: selectedModel,
          prompt: prompt,
        },
      };
      socket.send(JSON.stringify(message));
    }
  };

  const handleKey = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      handleSubmit(e);
      setPrompt("");
      setRows(2);
    } else if (e.key === "Enter") {
      setRows(rows + 1);
    }
  };

  const handleChange = (e) => {
    //shit entry sumit and enter add \n
    setPrompt(e.target.value);
  };
  return (
    <div className="w-full flex flex-col gap-2 mt-6">
      <form id="form_prompt" className="flex gap-2">
        <textarea
          id="prompt_input"
          name="prompt"
          value={prompt}
          onKeyDown={(e) => handleKey(e)}
          onChange={(e) => handleChange(e)}
          rows={rows}
          autoFocus
          className="block resize-y m-0 rounded-lg w-full border-[1px] border-primary bg-white/5 focus:ring-0 focus-visible:ring-0 dark:bg-transparent py-[10px] pr-10 md:py-3.5 md:pr-12 max-h-52 placeholder-black/50 min-h-8 dark:placeholder-white/50 pl-3 md:pl-4 text-primary_token"
          placeholder="Type a message..."
        ></textarea>
        <button
          type="submit"
          onClick={handleSubmit}
          className="absolute text-primary_token translate-y-4 right-7 p-2 bg-gray-600/30 rounded-md cursor-pointer hover:bg-gray-600/40 transition-colors"
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            className="text-white"
          >
            <path
              d="M7 11L12 6L17 11M12 18V7"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            ></path>
          </svg>
        </button>
      </form>
    </div>
  );
}

Chat.propTypes = {
  socket: PropTypes.oneOfType([PropTypes.object, PropTypes.oneOf([null])]),
};

function Chat({ socket }) {
  const navigate = useNavigate();
  const [chat, setChat] = useState([]);
  const location = useLocation();
  const query = new URLSearchParams(window.location.search);
  const selectedId = query.get("id");
  const [selecterId, setSelecterId] = useState("");
  const selectedModel = query.get("model");
  const chatRef = React.useRef(null);
  const [chat_len, setChat_len] = useState(0);
  const [loading, setLoading] = useState(true);
  const [loading_history, setLoading_history] = useState(false);
  const [loading_chat, setLoading_chat] = useState(false);
  const [start, setStart] = useState(0);
  const [end, setEnd] = useState(HISTORY);
  const [add_history, setAdd_history] = useState(true);

  useEffect(() => {
    if (add_history && chatRef.current) {
      chatRef.current.scrollTop = chatRef.current.scrollHeight;
    }
  }, [socket]);

  useEffect(() => {
    const handleScroll = () => {
      if (chatRef.current) {
        const { scrollTop } = chatRef.current;
        if (scrollTop === 0) {
          // Fetch old history here
          const query = new URLSearchParams(window.location.search);
          const id = query.get("id");
          const model = query.get("model");
          if (socket !== null) {
            setLoading_history(true);
            const getChat = {
              key: "chat",
              arg: "history-new",
              data: {
                id: id,
                model: model,
                start: start + end,
                end: end + HISTORY,
              },
            };
            try {
              socket.send(JSON.stringify(getChat));
            } catch (e) {
              console.log(e);
            }
          }
        }
      }
    };
    if (chatRef.current) {
      chatRef.current.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (chatRef.current) {
        chatRef.current.removeEventListener("scroll", handleScroll);
      }
    };
  }, [socket]);

  useEffect(() => {
    // get query params if change send history
    if (socket === null) {
      setLoading(true);
    }
    if (socket !== null) {
      if (socket.readyState === WebSocket.OPEN) {
        const params = new URLSearchParams(location.search);
        const id = params.get("id");
        const model = params.get("model");
        if (id !== selecterId) {
          setSelecterId(id);
          const getChat = {
            key: "chat",
            arg: "history",
            data: { id: id, model: model, start: 0, end: HISTORY },
          };
          try {
            socket.send(JSON.stringify(getChat));
          } catch (e) {
            console.log(e);
          }
        }
      }
    }
  }, [selectedId, location.search]);

  useEffect(() => {
    if (socket !== null) {
      setLoading(false);
      const getChat = {
        key: "chat",
        arg: "history",
        data: { id: selectedId, model: selectedModel, start: start, end: end },
      };

      const handleOpen = () => {
        if (chat.length === 0) {
          socket.send(JSON.stringify(getChat));
        }
      };

      const handleMessage = (event) => {
        const message = JSON.parse(event.data);
        try {
          if (message.key === "chat") {
            if (message.arg === "new-id") {
              navigate(`/?model=${selectedModel}&id=${message.data}`);
            } else if (message.arg === "add") {
              setChat_len(chat_len + 1);
              setChat((prevChat) => [...prevChat, ...message.data]);
              setLoading_chat(true);
            } else if (message.arg === "append-new") {
              setChat_len(chat_len + 1);
              setChat((prevChat) => [...prevChat, message.data]);
              setLoading_chat(false);
            } else if (message.arg === "append") {
              setLoading_chat(false);
              setChat((prevChat) => {
                const updatedChat = [
                  ...prevChat.slice(0, chat_len),
                  message.data,
                ];
                return updatedChat;
              });
            } else if (message.arg === "list-done") {
              setLoading_history(false);
              setAdd_history(false);
            } else if (message.arg === "list") {
              if (message.data.length !== 0) {
                setChat(message.data);
                setChat_len(message.data.length);
              } else {
                setLoading_history(false);
              }
            } else if (message.arg === "list-history") {
              setLoading_history(false);
              setStart(start + end);
              setEnd(end + HISTORY);
              const lastCHat = chat[0];
              const lastMessage = message.data[0];
              if (lastCHat.id === lastMessage.id) {
                setLoading_history(false);
                return;
              }
              // check last message if same as lat chat if not append
              setChat((prevChat) => [...message.data, ...prevChat]);
              setChat_len(message.data.length);
            } else if (message.arg === "done") {
              setLoading_chat(false);
              setChat_len(chat_len + 1);
            }
          }
        } catch (e) {
          console.log(e);
        }
      };

      if (socket.readyState === WebSocket.OPEN) {
        setLoading(false);
        handleOpen();
      } else {
        socket.addEventListener("open", handleOpen);
      }

      socket.addEventListener("message", handleMessage);

      return () => {
        socket.removeEventListener("open", handleOpen);
        socket.removeEventListener("message", handleMessage);
        setLoading(true);
      };
    }
  }, [socket, chat_len, chat, setChat, selectedId, selectedModel, navigate]);

  return (
    <div className="scrollbar flex flex-col justify-between max-h-[calc(100vh-69px-53px)] w-[calc(100vw-256px)] text-wrap p-4 pb-2 bg-chat text-primary_token overflow-y-auto">
      <div
        id="chat"
        ref={chatRef}
        className="scrollbar scroll-auto flex min-h-[calc(100vh-400px)] flex-col gap-2 overflow-y-scroll"
      >
        {loading ? (
          <Loading />
        ) : (
          <React.Fragment>
            {loading_history && <Loading_history />}
            <button
              id="scrollbar-bottom"
              className="block absolute h-8 w-8 flex items-center justify-center bottom-36 right-4 z-50 opacity-0 hover:opacity-100 duration-300 ease-in-out"
              onClick={() => {
                chatRef.current.scrollTop = chatRef.current.scrollHeight;
              }}
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill="currentColor"
                  d="m12 16.175l3.9-3.875q.275-.275.688-.288t.712.288q.275.275.275.7t-.275.7l-4.6 4.6q-.15.15-.325.213t-.375.062t-.375-.062t-.325-.213l-4.6-4.6q-.275-.275-.288-.687T6.7 12.3q.275-.275.7-.275t.7.275zm0-6L15.9 6.3q.275-.275.688-.287t.712.287q.275.275.275.7t-.275.7l-4.6 4.6q-.15.15-.325.213t-.375.062t-.375-.062t-.325-.213L6.7 7.7q-.275-.275-.288-.687T6.7 6.3q.275-.275.7-.275t.7.275z"
                />
              </svg>
            </button>
            <DisplayChat msg={chat} socket={socket} loading={loading_chat} />
          </React.Fragment>
        )}
      </div>
      <FormPrompt
        socket={socket}
        selectedId={selectedId}
        selectedModel={selectedModel}
      />
    </div>
  );
}

export default Chat;
