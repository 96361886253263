import React, { useEffect, useState } from "react";
import New_api from "./new_api";
import Cookies from "js-cookie";
import { GlobalApi } from "../globa";

const Apikey = () => {
  const [new_api, setNew_api] = useState(false);
  const [api, setApi] = useState([]);

  useEffect(() => {
    const get_api = async () => {
      try {
        const token = Cookies.get("token");
        const response = await fetch(`${GlobalApi()}/settings/get_api`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (response.ok) {
          const data = await response.json();
          if (data.status === "ok") {
            setApi(data.api);
          }
          console.log(data);
        }
      } catch (e) {
        console.log(e);
      }
    };
    get_api();
  }, []);

  const handleDelete = async (id) => {
    try {
      const token = Cookies.get("token");
      const response = await fetch(`${GlobalApi()}/settings/delete_api`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ id }),
      });
      if (response.ok) {
        const data = await response.json();
        if (data.status === "ok") {
          setApi(api.filter((item) => item.id !== id));
        }
      }
    } catch (e) {
      console.log(e);
    }
  };
  const convertToDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString();
  };
  return (
    <>
      {new_api && <New_api setNewApi={setNew_api} />}
      <div className="api-key p-6 text-white/50">
        <h1 className="text-lg">
          Manage your API keys. Remember to keep your API keys safe to prevent
          unauthorized access.
        </h1>
        <div className="w-full overflow-auto mt-6">
          <table className="w-full caption-bottom text-sm max-sm max-w[800px]">
            <thead className="[&_tr]:border-b hover:none">
              <tr className="border-b-[1px] border-white/10">
                <th className="text-left">Name</th>
                <th className="text-left">Key</th>
                <th className="text-left">Created</th>
                <th className="text-left">Last Used</th>
                <th className="h-12 py-3 pr-2 text-left align-middle text-xs font-medium uppercase tracking-wider [&:has([role=checkbox])]:pr-0 text-gray-500 dark:text-gray-400"></th>
              </tr>
            </thead>
            {api.map((item) => (
              <tbody key={item.id} className="[&_tr:last-child]:border-0 p-2">
                <tr className="border-b-[1px] border-white/10">
                  <th className="text-left">{item.name}</th>
                  <th className="text-left">{item.secret}</th>
                  <th className="text-left">{convertToDate(item.created)}</th>
                  <th className="text-left">{convertToDate(item.lastused)}</th>
                  <th className="text-left">
                    <button onClick={() => handleDelete(item.id)}>
                      <svg
                        className="text-red-500 hover:text-white ease-in-out duration-200"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill="currentColor"
                          d="M7 21q-.825 0-1.412-.587T5 19V6H4V4h5V3h6v1h5v2h-1v13q0 .825-.587 1.413T17 21zm2-4h2V8H9zm4 0h2V8h-2z"
                        />
                      </svg>
                    </button>
                  </th>
                </tr>
              </tbody>
            ))}
          </table>
          <div className="mt-6">
            <button
              onClick={() => setNew_api(true)}
              className="text-sm text-white p-2 bg-primary rounded-md"
            >
              Create API key
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Apikey;
